import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Obfuscate from "react-obfuscate"

const Footer = class extends React.Component {

  render() {
    return (
      <footer>
        <div className="container">
          <div className="columns is-centered" style={{margin: '20px 0'}}>
            <div className="column is-4 is-offset-1">
              <p className="title-footer">{this.props.langKey === 'fr' ? 'Contact' : 'Contact us'}</p>
              <div className="title-footer-decoration"></div>
              <p>14 rue de la Fayette Bâtiment B</p>
              <p>25000 - Besançon</p><br/>
              <p>{this.props.langKey === 'fr' ? 'Tél.' : 'Phone'} : +33 3 81 47 70 38</p>
              <p>Email : <Obfuscate email="aprogsys@aprogsys.com" className="has-text-primary">aprogsys@aprogsys.com</Obfuscate>
              </p>
            </div>
            <div className="column is-3">
              <p className="title-footer">{this.props.langKey === 'fr' ? 'A propos' : 'About'}</p>
              <div className="title-footer-decoration"></div>
              <Link to={this.props.langKey+"/societe/"}>&gt; {this.props.langKey === 'fr' ? 'L\'entreprise' : 'Company'}</Link><br/>
              <Link to={this.props.langKey+"/metiers/"}>&gt; {this.props.langKey === 'fr' ? 'Ingénierie informatique' : 'Software engineering'}</Link><br/>
              <Link to={this.props.langKey+"/mentions-legales/"}>&gt; {this.props.langKey === 'fr' ? 'Mentions légales' : 'Legal information'}</Link>
            </div>
            <div className="column is-3">
              <p className="title-footer">{this.props.langKey === 'fr' ? 'Recrutement' : 'Recruitment'}</p>
              <div className="title-footer-decoration"></div>
              <Link to={this.props.langKey+"/societe/#recrutement"}>> {this.props.langKey === 'fr' ? 'Rejoignez-nous !' : 'Join us!'}</Link>
            </div>
          </div>
          <div className="has-text-centered" style={{paddingBottom:'20px'}}>
            © {new Date().getFullYear()}, Aprogsys
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
  langKey: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
  langKey: 'fr'
}

export default Footer
