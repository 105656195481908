import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const MentionsLegales = () => (
  <Layout langKey={"fr"}>
    <SEO title="Mentions légales" />
    <section className="section">
      <div className="container">
        <h1>Mentions légales</h1>
        <h2>Éditeur</h2>
        <p>
          <strong>Aprogsys</strong>
          <br />
          Au capital de 60 000 €<br />
          14b rue la Fayette
          <br />
          25000 Besançon
          <br />
          Tel. : +33 (0)3 81 47 70 38
          <br />
          SIRET : 437.768.385.00019
        </p>
        <h2>Hébergeur</h2>
        <p>
          Le serveur du site de Aprogsys est hébergé en France par :<br /><br/>
          <strong>OVH</strong>
          <br />
          2 rue Kellermann
          <br />
          59100 Roubaix
          <br />
          FRANCE
          <br />
          <a href="https://www.ovh.com">www.ovh.com</a>
        </p>
        <h2>Données personnelles</h2>
        <p>Le site www.aprogsys.com ne stocke pas de données personnelles.</p>
      </div>
    </section>
  </Layout>
)

export default MentionsLegales
