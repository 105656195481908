import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { ReactComponent as Logo } from "../img/Aprogsys.svg"


const Header = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    this.setState({ active: !this.state.active },
      () => {
        this.state.active
          ? this.setState({ navBarActiveClass: 'is-active' })
          : this.setState({ navBarActiveClass: '' })
      }
    )
  }
  render() {
    return (
      <header className="header">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item" to={this.props.langKey+"/"}>
                <Logo style={{height:80}}/>
              </Link>

              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                aria-label="menu"
                aria-expanded="false"
                data-target="mainNavbar"
                onClick={ () => this.toggleHamburger() }
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </div>
            </div>
            <div id="mainNav" className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-start language" style={{ alignItems: 'flex-end' }}>
                <Link className="navbar-item has-text-primary language-fr" style={{fontSize:'0.9rem'}} to="fr/">
                  Fr
                </Link>
                <div className={`language-bar`} style={{ height: '0.8rem', borderRight: '1px solid #ddd' }} />
                <Link className="navbar-item has-text-primary language-en" style={{fontSize:'0.9rem'}} to="en/">
                  En
                </Link>
              </div>
              <div className="navbar-end" style={{ alignItems: 'flex-end' }}>
                <Link className="navbar-item has-text-primary" style={{fontSize:'1.4rem',marginRight:'1rem'}} to={this.props.langKey+"/metiers/"}>
                  {this.props.langKey === 'fr' ? 'Nos métiers' : 'Our activities'}
                </Link>

                <Link className="navbar-item has-text-primary" style={{fontSize:'1.4rem',marginRight:'1rem'}} to={this.props.langKey+"/domaines/"}>
                  {this.props.langKey === 'fr' ? 'Nos domaines d\'expertise' : 'Our expertise'}
                </Link>

                <Link className="navbar-item has-text-primary" style={{fontSize:'1.4rem',marginRight:'3rem'}} to={this.props.langKey+"/societe/"}>
                  {this.props.langKey === 'fr' ? 'La société Aprogsys' : 'About us'}
                </Link>
              </div>
              
            </div>
            
          </div>
        </nav>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  langKey: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  langKey: 'fr'
}

export default Header
